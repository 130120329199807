import React, { FC } from "react";
import { Badge, Flex, Popover, Table, TableColumnType, TablePaginationConfig, Tag, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";

import { CloudUploadOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { ActionsCell, CardView } from "@ni/common/ui";
import { dateFormat } from "@ni/common/utils";
import {
  Deployment,
  DeploymentsState,
  DeploymentsState as DeploymentsStateType,
  Order,
  SortedFilteredPageRequest,
} from "@ni/sdk/models";

import { getStatusColor } from "../../utils";

import styles from "./styles.module.scss";

interface DeploymentsTableViewProps {
  deploymentList: Deployment[];
  pagination: TablePaginationConfig;
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setDeploymentsFilters: React.Dispatch<React.SetStateAction<SortedFilteredPageRequest>>;
  fetchDepeloymentById: (id: number) => void;
  reTryDeployment: (deploymentId: number) => void;
}

export const DeploymentsTable: FC<DeploymentsTableViewProps> = ({
  deploymentList,
  pagination,
  setPagination,
  setDeploymentsFilters,
  fetchDepeloymentById,
  reTryDeployment,
}) => {
  const navigate = useNavigate();

  const [isLoading] = useReduxState("isLoading", false);

  const onRowHandler = (item: Deployment) => {
    return {
      onDoubleClick: () => navigate(`${item.id}`),
    };
  };

  const handleChange = (
    pagination: TablePaginationConfig,
    _: Record<string, FilterValue | null>,
    sorter: SorterResult<Deployment> | SorterResult<Deployment>[],
  ) => {
    let newSorting: Array<Order> = [];
    if (Object.keys(sorter).length) {
      const sorterArray = Array.isArray(sorter) ? sorter : [sorter];

      newSorting = sorterArray.map(sorterItem => {
        const { order, columnKey } = sorterItem;

        if (!order) {
          return undefined;
        }

        return {
          direction: order === "ascend" ? "ASC" : order === "descend" ? "DESC" : "",
          property: columnKey,
        };
      }) as Array<Order>;
    }

    setPagination(pagination);

    setDeploymentsFilters(existingFilters => ({
      ...existingFilters,
      sorting: newSorting.filter(Boolean),
    }));
  };

  const getActionItems = (deploymentsState: DeploymentsStateType) => {
    const actions = [
      {
        label: "Refresh",
        icon: <RedoOutlined />,
        actionCallBack: (item: Deployment) => {
          fetchDepeloymentById(item.id!);
        },
      },
      {
        label: "View details",
        icon: <SearchOutlined />,
        actionCallBack: (item: Deployment) => {
          navigate(`${item.id}`);
        },
      },
    ];

    if (deploymentsState === DeploymentsState.FAILURE) {
      actions.push({
        label: "Retry deployment",
        icon: <CloudUploadOutlined />,
        actionCallBack: (item: Deployment) => {
          reTryDeployment(Number(item.id));
        },
      });
    }
    return actions;
  };

  const columns: TableColumnType<Deployment>[] = [
    {
      title: "FI",
      dataIndex: ["tenantExternalCode"],
      key: "tenantExternalCode",
      width: "3%",
      align: "center" as const,
      rowScope: "row",
      render: (_: string, { tenantExternalCode }: Deployment) => tenantExternalCode ?? EMPTY_DATA_FLAG,
    },
    {
      title: "Tenant",
      dataIndex: ["tenantName"],
      key: "tenantName",
      width: "9%",
      ellipsis: true,
      render: (_: string, { tenantName }: Deployment) => tenantName ?? EMPTY_DATA_FLAG,
    },
    {
      title: "Product",
      dataIndex: ["productName"],
      key: "productName",
      width: "15%",
      ellipsis: true,
      render: (_: string, item: Deployment) => {
        const productType: string | undefined = item.productName?.split(" ")[0];

        const getProdIps = () => {
          if (productType === "MasterCard") return "MC";
          if (productType === "Visa") return "Visa";
          return "MC";
        };

        return (
          <Flex align="center" gap={8}>
            <CardView cardSize="smallest" cardImage="default" prodIps={getProdIps()} />
            <Typography.Text ellipsis={true}>
              <strong>{item.productExternalCode}</strong> {item.productName}
            </Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Start Time",
      dataIndex: ["startTime"],
      key: "startTime",
      width: "9%",
      ellipsis: true,
      sorter: (a, b) => Number(a.startTime) - Number(b.startTime),
      render: (_: string, { startTime }: Deployment) => (startTime ? dateFormat(startTime) : EMPTY_DATA_FLAG),
    },
    {
      title: "Jira Ticket ID",
      dataIndex: ["jiraTicket"],
      key: "jiraTicket",
      width: "10%",
      ellipsis: true,
      render: (_: string, item: Deployment) => {
        const jiraTickets = item.jiraTicket?.split(",");

        if (!jiraTickets?.length) return EMPTY_DATA_FLAG;

        const content = (): React.ReactNode => {
          return jiraTickets.map(jiraTicket => (
            <Tag key={jiraTicket}>
              <Typography.Text copyable={true} ellipsis={true} style={{ fontSize: 13 }}>
                {jiraTicket}
              </Typography.Text>
            </Tag>
          ));
        };

        if (jiraTickets.length > 1) {
          return (
            <Popover title="Jira Tickets" content={content} trigger="hover" placement="bottomLeft">
              {content()}
            </Popover>
          );
        }

        return content();
      },
    },
    {
      title: "Additional Notes",
      dataIndex: ["additionalNotes"],
      key: "additionalNotes",
      width: "10%",
      ellipsis: true,
      render: (_: string, item: Deployment) => {
        if (!item.additionalNotes?.trim()) return EMPTY_DATA_FLAG;

        const content = (): React.ReactNode => (
          <Typography.Text copyable={true} ellipsis={true}>
            {item.additionalNotes}
          </Typography.Text>
        );

        if (item.additionalNotes?.length >= 25) {
          return (
            <Popover title="Additional Notes" content={content} trigger="hover" placement="bottomLeft">
              {content()}
            </Popover>
          );
        }

        return content();
      },
    },
    {
      title: "Status",
      dataIndex: ["state"],
      key: "state",
      width: "5%",
      ellipsis: true,
      rowScope: "row",
      render: (_: string, item: Deployment) => {
        const { status, text } = getStatusColor(item.state as DeploymentsStateType);
        return <Badge status={status} text={text} />;
      },
    },
    {
      title: "",
      key: "id",
      width: "2%",
      rowScope: "row",
      render: (_: string, item: Deployment) => (
        <Flex justify="center" align="center">
          <ActionsCell items={getActionItems(item.state as DeploymentsStateType)} rowData={item} />
        </Flex>
      ),
    },
  ];

  return (
    <Table<Deployment>
      rowKey="id"
      size="small"
      tableLayout="fixed"
      className={styles["ni-table"]}
      columns={columns}
      pagination={pagination}
      dataSource={deploymentList}
      onRow={onRowHandler}
      onChange={handleChange}
      loading={isLoading}
      bordered={true}
    />
  );
};
