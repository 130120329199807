import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Descriptions,
  DescriptionsProps,
  Divider,
  Flex,
  Space,
  Table,
  TableColumnType,
  Tree,
  Typography,
} from "antd";
import { Link, useParams } from "react-router-dom";

import { DownOutlined, ReloadOutlined } from "@ant-design/icons";
import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { SettingsPageLayout } from "@ni/common/ui";
import { dateFormat, durationConvertHandler } from "@ni/common/utils";
import { Deployment, DeploymentDetails as DeploymentDetailsType, DeploymentsState } from "@ni/sdk/models";

import { getStatusColor } from "../../utils";

import { useDeploymentDetails } from "./useDeploymentDetails";

import styles from "./styles.module.scss";

interface IntegrationData {
  key: string;
  value?: string;
  status?: DeploymentsState;
  treeData?: Array<{
    key: string;
    title: string;
    children: Array<{
      title: string;
      key: string;
      children: Array<{ title: string; key: string }>;
    }>;
  }>;
}

const pollDuration: number = 30000;

export const DeploymentDetails = () => {
  const { deploymentId } = useParams();
  const { fetchDepeloymentById, retryDeployment, deployment, isLoading } = useDeploymentDetails();
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const handleExpand = useCallback((record: DeploymentDetailsType) => {
    setExpandedRowKeys(prev =>
      prev.includes(record.id as React.Key)
        ? prev.filter(key => key !== record.id)
        : ([...prev, record.id] as React.Key[]),
    );
  }, []);

  const shouldRedeploy = useMemo(() => deployment.state === DeploymentsState.FAILURE, [deployment.state]);

  const integrationsData = useMemo((): IntegrationData[] => {
    if (!deployment.integrationPr) return [];

    return Object.entries(deployment.integrationPr).map(([key, value]) => {
      if (key.includes("error")) {
        return {
          key,
          treeData: Object.entries(value ?? {})
            .filter(([k]) => k !== "failed")
            .map(([k, v]) => ({
              key: k,
              title: k,
              children: Object.entries(v).map(([childKey, childValue]) => ({
                title: childKey,
                key: childKey,
                children: (childValue as string[]).map(x => ({ title: x, key: x })),
              })),
            })),
          status: DeploymentsState.FAILURE,
        };
      }

      return { key, value: value as string };
    });
  }, [deployment.integrationPr]);

  useEffect(() => {
    const id = Number(deploymentId);
    fetchDepeloymentById(id, true);

    if (deployment.reason) {
      const errorId = deployment.deploymentDetails?.find(({ stageState }) => stageState === DeploymentsState.FAILURE)
        ?.id as React.Key;
      if (errorId) setExpandedRowKeys([errorId]);
    }

    const pollInterval = setInterval(() => {
      if (deployment.state === DeploymentsState.IN_PROGRESS) {
        fetchDepeloymentById(id, false);
      }
    }, pollDuration);

    return () => clearInterval(pollInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deployment.reason, deployment.state, deploymentId, fetchDepeloymentById]);

  const { stageItems, deploymentColumns, integrationsColumns } = useMemo(() => {
    const stageItems: DescriptionsProps["items"] = [
      {
        key: "1",
        label: "Tenant External Code",
        children: deployment.tenantExternalCode,
      },
      {
        key: "2",
        label: "Product External Code",
        children: deployment.productExternalCode,
      },
      {
        key: "3",
        label: "Trace ID",
        children: deployment.traceId && (
          <Typography.Text copyable={true} ellipsis={true}>
            {deployment.traceId}
          </Typography.Text>
        ),
      },
      {
        key: "4",
        label: "Branch Name",
        children: deployment.branchName,
      },
      {
        key: "5",
        label: "Pull Request Link",
        children: deployment.prLink && (
          <Link to={deployment.prLink} target="_blank" rel="noopener noreferrer">
            <Typography.Text copyable={true} ellipsis={true}>
              {deployment.prLink}
            </Typography.Text>
          </Link>
        ),
        span: 2,
      },
      {
        key: "6",
        label: "Status",
        children: (
          <Flex align="center">
            <Badge {...getStatusColor(deployment.state!)} />

            {shouldRedeploy && (
              <Button
                size="small"
                type="link"
                htmlType="button"
                icon={<ReloadOutlined />}
                loading={isLoading}
                onClick={() => retryDeployment(deployment.id!)}
                style={{ marginLeft: "auto", padding: 0 }}
              >
                Retry Deployment
              </Button>
            )}
          </Flex>
        ),
        span: 3,
      },
    ];

    const deploymentColumns: TableColumnType<DeploymentDetailsType>[] = [
      {
        title: "Stage",
        dataIndex: ["stageName"],
        key: "stageName",
        width: "30%",
        rowScope: "row",
        ellipsis: true,
        render: (_: string, { stageName }: DeploymentDetailsType) => stageName,
      },
      {
        title: "Start Time",
        dataIndex: ["startTime"],
        key: "startTime",
        width: "20%",
        ellipsis: true,
        render: (_: string, { startTime }: DeploymentDetailsType) =>
          startTime ? dateFormat(startTime) : EMPTY_DATA_FLAG,
      },
      {
        title: "Total Time",
        dataIndex: ["totalTime"],
        key: "totalTime",
        width: "20%",
        ellipsis: true,
        render: (_: string, { totalTime }: DeploymentDetailsType) =>
          totalTime ? <>{durationConvertHandler(totalTime as string).toFixed(3)} sec</> : EMPTY_DATA_FLAG,
      },
      {
        title: "Status",
        dataIndex: ["stageState"],
        key: "stageState",
        width: "5%",
        rowScope: "row",
        ellipsis: true,
        render: (_: string, { stageState }: DeploymentDetailsType) => <Badge {...getStatusColor(stageState!)} />,
      },
    ];

    const integrationsColumns: TableColumnType<IntegrationData>[] = [
      {
        key: "system",
        title: "System Name",
        dataIndex: ["key"],
        width: "50%",
      },
    ];

    if (integrationsData.some(x => x.key.includes("error"))) {
      integrationsColumns.push({
        key: "status",
        title: "Status",
        dataIndex: ["status"],
        width: "3.5%",
        rowScope: "row",
        render: (_: string, { status: stageState }: IntegrationData) => <Badge {...getStatusColor(stageState!)} />,
      });
    } else {
      integrationsColumns.push({
        key: "link",
        title: "Pull Request Link",
        width: "50%",
        render: (_, item) =>
          item.value && (
            <Link to={item.value} target="_blank" rel="noopener noreferrer">
              <Typography.Text copyable={true}>{item?.value}</Typography.Text>
            </Link>
          ),
      });
    }

    return { stageItems, deploymentColumns, integrationsColumns };
  }, [
    deployment.branchName,
    deployment.id,
    deployment.prLink,
    deployment.productExternalCode,
    deployment.state,
    deployment.tenantExternalCode,
    deployment.traceId,
    integrationsData,
    isLoading,
    retryDeployment,
    shouldRedeploy,
  ]);

  return (
    <SettingsPageLayout pageTitle="Deployment Details" size="full" link="/admin/deployments" linkTitle="Deployments">
      <Space direction="vertical" size="large">
        <div>
          <Divider orientation="left" orientationMargin={0} style={{ marginTop: 0 }}>
            NetworkOne Stages
          </Divider>

          <Descriptions
            size="middle"
            items={stageItems}
            bordered={true}
            labelStyle={{ fontWeight: 500, color: "black" }}
            className={styles["ni-stage-details"]}
          />
        </div>

        <Table<Deployment>
          size="middle"
          rowKey="id"
          columns={deploymentColumns}
          dataSource={deployment.deploymentDetails || []}
          expandable={{
            expandedRowKeys,
            expandIconColumnIndex: -1,
            expandedRowRender: () => (
              <Alert
                type="error"
                message="An Error Occurred"
                description={deployment.reason}
                showIcon={true}
                closable={true}
                onClose={() => {
                  const errorIndex =
                    deployment.deploymentDetails?.findIndex(x => x.stageState === DeploymentsState.FAILURE) ?? 0;
                  setExpandedRowKeys(prev => prev.filter(key => key !== deployment.deploymentDetails?.[errorIndex].id));
                }}
              />
            ),
            onExpand: (_, record) => {
              handleExpand(record);
            },
          }}
          loading={isLoading}
          pagination={false}
          bordered={true}
        />
      </Space>

      {deployment.state === DeploymentsState.SUCCESS && (
        <>
          <Divider orientation="left" orientationMargin={0}>
            Integrations Stages
          </Divider>

          <Table<IntegrationData>
            size="middle"
            rowKey="key"
            columns={integrationsColumns}
            dataSource={integrationsData}
            expandable={{
              expandedRowKeys: ["dudamel-error"],
              expandIconColumnIndex: -1,
              expandedRowRender: record => (
                <Tree
                  showLine={true}
                  selectable={false}
                  defaultExpandAll={true}
                  switcherIcon={<DownOutlined />}
                  treeData={record.treeData}
                />
              ),
              onExpand: (_, record) => {
                handleExpand(record);
              },
            }}
            loading={isLoading}
            pagination={false}
            bordered={true}
          />
        </>
      )}
    </SettingsPageLayout>
  );
};
