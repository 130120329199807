import { useCallback, useMemo, useState } from "react";
import { Button, Collapse, Flex, Input, notification, Typography } from "antd";

import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { SettingsPageLayout } from "@ni/common/ui";
import { DevFeatureControllerApi } from "@ni/sdk/apis";
import { RestDevFeatureRequest } from "@ni/sdk/models";

const Support = () => {
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [qpPayload, setQpPayload] = useState<RestDevFeatureRequest[]>([{ fiCode: "", branchName: "" }]);

  const devFeaturesApi = useMemo(() => new DevFeatureControllerApi(), []);

  const onRunQpScript = useCallback(async () => {
    setIsLoading(true);

    try {
      await devFeaturesApi.runQpScripts(qpPayload);

      notification.success({
        message: "Success",
        description: "Scripts have been run successfully",
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while running the scripts",
      });
    } finally {
      setIsLoading(false);
    }
  }, [devFeaturesApi, qpPayload, setIsLoading]);

  const handleInputChange = useCallback((index: number, field: string, value: string) => {
    setQpPayload(prev => prev.map((item, i) => (i === index ? { ...item, [field]: value } : item)));
  }, []);

  return (
    <SettingsPageLayout pageTitle="Support" pageSubtitle="" size="full">
      <Collapse defaultActiveKey="1">
        <Collapse.Panel key="1" header="Scripts">
          <div>
            <Flex justify="space-between" align="flex-start" gap={16}>
              <div>
                <Typography.Text strong={true}>Questionnaire Processing Scripts</Typography.Text>
                <Typography.Paragraph type="secondary" style={{ maxWidth: 880 }}>
                  This feature is used to run scripts for Questionnaire Processing. Please provide the financial
                  institution code and branch name for each object created.
                </Typography.Paragraph>
              </div>

              <Button
                type="primary"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                loading={isLoading}
                onClick={onRunQpScript}
              >
                Run
              </Button>
            </Flex>

            <Flex vertical={true} gap={8}>
              <Typography.Text strong={true}>Payload</Typography.Text>

              {qpPayload.map((item, index) => (
                <Flex key={`object-${index + 1}`} vertical={true} gap={8}>
                  <Flex align="center" gap={8}>
                    <Input
                      placeholder="Financial institution code"
                      onChange={e => handleInputChange(index, "fiCode", e.target.value)}
                      value={item.fiCode}
                    />
                    <Input
                      placeholder="Branch name"
                      onChange={e => handleInputChange(index, "branchName", e.target.value)}
                      value={item.branchName}
                    />

                    <Button
                      type="primary"
                      shape="circle"
                      danger={true}
                      icon={<DeleteOutlined />}
                      disabled={qpPayload.length === 1}
                      tabIndex={-1}
                      onClick={() => setQpPayload(qpPayload.filter((_, i) => i !== index))}
                    />
                  </Flex>
                </Flex>
              ))}

              <Button
                type="primary"
                onClick={() => setQpPayload([...qpPayload, { fiCode: "", branchName: "" }])}
                style={{ width: 150, marginLeft: "auto", marginTop: 16 }}
              >
                Add
              </Button>
            </Flex>
          </div>
        </Collapse.Panel>
      </Collapse>
    </SettingsPageLayout>
  );
};

export { Support };
