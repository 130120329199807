import { FilterInputProps } from "@ni/common/ui";

export const inputs: FilterInputProps[] = [
  {
    name: "deployment.state",
    type: "select",
    props: {
      placeholder: "Status",
      options: [
        { label: "All States", value: null },
        { label: "Failure", value: "FAILURE" },
        { label: "Success", value: "SUCCESS" },
        { label: "In Progress", value: "IN_PROGRESS" },
      ],
    },
  },
  {
    name: "dateRange",
    type: "dateRange",
    props: {
      placeholder: ["From", "To"],
    },
  },
];
