interface Props extends React.HTMLAttributes<HTMLDivElement> {
  height?: "auto" | number;
}

const ScrollArea = ({ height, ...props }: Props) => {
  const style: React.CSSProperties = {
    maxHeight: height === "auto" ? "auto" : `${height}px`,
    overflowY: "auto",
    width: "100%",
  };

  return <div style={style} {...props} />;
};

export { ScrollArea };
