import { useEffect, useState } from "react";
import { Button, Modal, notification, Table, TableColumnType, Tooltip, Typography } from "antd";
import { useParams } from "react-router-dom";

import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { PageItemLayoutElements, ScrollArea, SettingsPageLayout } from "@ni/common/ui";
import { dateFormat, durationConvertHandler } from "@ni/common/utils";
import { Deployment, DeploymentDetails as DeploymentDetailsType } from "@ni/sdk/models";

import { DEPLOYMENT_STATE_COLORS } from "../../constants";

import { useDeploymentDetails } from "./useDeploymentDetails";

import styles from "./styles.module.scss";

const { Title, Text } = Typography;

export const DeploymentDetails = () => {
  const { deploymentId } = useParams();
  const { fetchDepeloymentById, deployment, isLoading } = useDeploymentDetails();
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false);

  useEffect(() => {
    fetchDepeloymentById(Number(deploymentId));
  }, [deploymentId, fetchDepeloymentById]);

  const onCopy = (target: string, text: string) => {
    navigator.clipboard
      .writeText(target)
      .then(() => {
        notification.success({
          placement: "topRight",
          duration: 5,
          message: `${text} copied successfully`,
        });
      })
      .catch(() => {
        notification.error({
          placement: "topRight",
          duration: 5,
          message: "Something went wrong, could not copy.",
        });
      });
  };

  const columns: TableColumnType<Deployment>[] = [
    {
      title: "Tenant external code",
      dataIndex: ["tenantExternalCode"],
      key: "tenantExternalCode",
      width: 150,
      ellipsis: true,
      render: (_: string, item: Deployment) => item.tenantExternalCode,
    },
    {
      title: "Product external code",
      dataIndex: ["productExternalCode"],
      key: "productExternalCode",
      width: 150,
      ellipsis: true,
      render: (_: string, item: Deployment) => item.productExternalCode,
    },
    {
      title: "Trace ID",
      dataIndex: ["traceId"],
      key: "traceId",
      width: 150,
      ellipsis: true,
      render: (_: string, { traceId }: Deployment) => (
        <Tooltip title="Copy Trace ID" placement="bottom">
          <Typography.Text ellipsis={true} onClick={() => onCopy(traceId as string, "Trace ID")} role="presentation">
            {traceId}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Branch name",
      dataIndex: ["branchName"],
      key: "branchName",
      width: 150,
      ellipsis: true,
      render: (_: string, item: Deployment) => {
        return item.branchName ? (
          <Tooltip title="Copy Branch Name" placement="bottom">
            <Typography.Text
              ellipsis={true}
              onClick={() => onCopy(item.branchName as string, "Branch Name")}
              role="presentation"
            >
              {item.branchName}
            </Typography.Text>
          </Tooltip>
        ) : (
          EMPTY_DATA_FLAG
        );
      },
    },
    {
      title: "Link to PR",
      dataIndex: ["prLink"],
      key: "prLink",
      width: 150,
      ellipsis: true,
      render: (_: string, item: Deployment) => {
        return item.prLink ? (
          <Tooltip title="Copy PR Link" placement="bottom">
            <Typography.Text
              ellipsis={true}
              onClick={() => onCopy(item.prLink as string, "PR Link")}
              role="presentation"
            >
              {item.prLink}
            </Typography.Text>
          </Tooltip>
        ) : (
          EMPTY_DATA_FLAG
        );
      },
    },
  ];

  const stepsColumns: TableColumnType<DeploymentDetailsType>[] = [
    {
      title: "Stage name",
      dataIndex: ["stageName"],
      key: "stageName",
      width: "35%",
      ellipsis: true,
      render: (_: string, { stageName }: DeploymentDetailsType) => stageName,
    },
    {
      title: "Stage status",
      dataIndex: ["stageState"],
      key: "stageState",
      width: "15%",
      ellipsis: true,
      render: (_: string, { stageState }: DeploymentDetailsType) =>
        stageState ? <Text type={DEPLOYMENT_STATE_COLORS[stageState]}>{stageState}</Text> : EMPTY_DATA_FLAG,
    },
    {
      title: "Start time",
      dataIndex: ["startTime"],
      key: "startTime",
      width: "20%",
      ellipsis: true,
      render: (_: string, { startTime }: DeploymentDetailsType) =>
        startTime ? dateFormat(startTime) : EMPTY_DATA_FLAG,
    },
    {
      title: "Total time",
      dataIndex: ["totalTime"],
      key: "totalTime",
      width: "10%",
      ellipsis: true,
      render: (_: string, { totalTime }: DeploymentDetailsType) =>
        totalTime ? <>{durationConvertHandler(totalTime as string).toFixed(3)} sec</> : EMPTY_DATA_FLAG,
    },
    {
      title: "",
      dataIndex: ["actions"],
      key: "actions",
      width: "15%",
      render: (_: string, item: DeploymentDetailsType) => {
        return item.stageState === "FAILURE" ? (
          <div className="flex flex-align-center flex-justify-center">
            <Button
              type="primary"
              onClick={() => {
                setIsErrorModelOpen(true);
              }}
            >
              Details
            </Button>
            <Modal
              title="Error Message"
              open={isErrorModelOpen}
              onCancel={() => {
                setIsErrorModelOpen(false);
              }}
              footer={[
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => {
                    setIsErrorModelOpen(false);
                  }}
                >
                  Ok
                </Button>,
              ]}
            >
              <ScrollArea height={500}>{deployment.reason}</ScrollArea>
            </Modal>
          </div>
        ) : null;
      },
    },
  ];

  return (
    <SettingsPageLayout pageTitle="Deployments details" size="full" link="/admin/deployments" linkTitle="Deployments">
      <PageItemLayoutElements>
        <Table<Deployment>
          rowKey="id"
          className={styles["ni-deployment-details"]}
          columns={columns}
          dataSource={deployment?.id ? [deployment] : []}
          loading={isLoading}
          pagination={false}
        />

        <Title level={3}>Deployments stages</Title>
        <Table<Deployment>
          rowKey="id"
          className={styles["ni-deployment-details"]}
          columns={stepsColumns}
          dataSource={deployment.deploymentDetails || []}
          loading={isLoading}
          pagination={false}
        />
      </PageItemLayoutElements>
    </SettingsPageLayout>
  );
};
